/* You can add global styles to this file, and also import other style files */
@import '@savvaslearning/cel-components/dist-stencil/build/lib.css';
@import '~@savvaslearning/font-open-sans/font-open-sans.css';
@import '~@savvaslearning/font-poppins/font-poppins.css';
@import 'styles/themes/typography-variables';
@import 'styles/themes/color-variables';


html, body {
    overflow-y: hidden;
    margin: 0; /* Remove default margin */
}
