:root {
    --grayLight: #cdcdcd;
    --grayWhite: #f9f9f9;
    --grayDarker: #333333;
    --whiteSmoke: #efefef;
    --grayLighter: #e2e2e2;
    --white: #fff;
    --box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3);
    --black: #000000;
    --blueLight: #026ecb;
    --blue: #0072ee;
}