:root {
    /* Opn Sans Fonts */
    --font-family-open-sans-bold: 'OpenSans-Bold';
    --font-family-open-sans-semi-bold: 'OpenSans-Semibold';
    --font-family-open-sans: 'OpenSans';
    --primary-font: 'OpenSans';


    /* Poppin Fonts */
    --font-family-poppin-semi-bold: 'Poppins-SemiBold';
    --font-family-poppin-medium: 'Poppins-Medium.ttf';
    --font-poppin-regular: 'Poppins-Regular';


    /* Text Sizes */
    --text-size-xxx-large: 1.875rem; /* 1.875rem (30px) */
    --text-size-xx-large: 1.375rem; /* 1.375rem (22px) */
    --text-size-x-large: 1.25rem; /* 1.25rem (20px) */
    --text-size-large: 1.125rem; /* 1.125rem (18px) */
    --text-size-normal: 1rem; /* 1rem (16px) */
    --text-size-medium: 0.9375rem; /* 0.9375rem (15px)*/
    --text-size-small: 0.875rem; /* 0.875rem (14px) */
    --text-size-x-small: 0.75rem; /* 0.75rem (12px) */

    /* Font Sizes */
    --font-size-regular: 14px;
    --font-size-x-regular: 15px;
    --font-size-xx-regular: 16px;
    --font-size-large: 20px;
    --font-size-m-large: 22px;
    --font-size-x-large: 28px;
    --font-size-small: 12px;
}